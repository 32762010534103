import * as React from 'react';
import { Autocomplete, /* createFilterOptions, FormControl, */ FormLabel} from '@mui/joy';
// import AutocompleteOption from '@mui/joy/AutocompleteOption';
// import ListItemDecorator from '@mui/joy/ListItemDecorator';
// import Add from '@mui/icons-material/Add';

// const filter = createFilterOptions();

const IndustrySelect = ( {industry, setIndustry} ) => {

    const businessClassifications = {
		"classifications":[
			{
				"category": "Agriculture, Forestry and Fishing",
				"classifcations": [
					"Growing of cereals (except rice), leguminous crops and oil seeds",
					"Growing of rice",
					"Growing of vegetables and melons, roots and tubers",
					"Growing of sugar cane",
					"Growing of tobacco",
					"Growing of fibre crops",
					"Growing of other non-perennial crops",
					"Growing of grapes",
					"Growing of tropical and subtropical fruits",
					"Growing of citrus fruits",
					"Growing of pome fruits and stone fruits",
					"Growing of other tree and bush fruits and nuts",
					"Growing of oleaginous fruits",
					"Growing of beverage crops",
					"Growing of spices, aromatic, drug and pharmaceutical crops",
					"Growing of other perennial crops",
					"Plant propagation",
					"Raising of dairy cattle",
					"Raising of other cattle and buffaloes",
					"Raising of horses and other equines",
					"Raising of camels and camelids",
					"Raising of sheep and goats",
					"Raising of swine/pigs",
					"Raising of poultry",
					"Raising of other animals",
					"Mixed farming",
					"Support activities for crop production",
					"Farm animal boarding and care",
					"Support activities for animal production (other than farm animal boarding and care) n.e.c.",
					"Post-harvest crop activities",
					"Seed processing for propagation",
					"Hunting, trapping and related service activities",
					"Silviculture and other forestry activities",
					"Logging",
					"Gathering of wild growing non-wood products",
					"Support services to forestry",
					"Marine fishing",
					"Freshwater fishing",
					"Marine aquaculture",
					"Freshwater aquaculture"
				]
			},
	
			{
				"category": "Mining and Quarrying",
				"classifcations": [
					"Deep coal mines",
					"Open cast coal working",
					"Mining of lignite",
					"Extraction of crude petroleum",
					"Extraction of natural gas",
					"Mining of iron ores",
					"Mining of uranium and thorium ores",
					"Mining of other non-ferrous metal ores",
					"Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate",
					"Operation of gravel and sand pits; mining of clays and kaolin",
					"Mining of chemical and fertilizer minerals",
					"Extraction of peat",
					"Extraction of salt",
					"Other mining and quarrying n.e.c.",
					"Support activities for petroleum and natural gas mining",
					"Support activities for other mining and quarrying"
				]
			},
	
			{
				"category": "Manufacturing",
				"classifcations": [
					"Processing and preserving of meat",
					"Processing and preserving of poultry meat",
					"Production of meat and poultry meat products",
					"Processing and preserving of fish, crustaceans and molluscs",
					"Processing and preserving of potatoes",
					"Manufacture of fruit and vegetable juice",
					"Other processing and preserving of fruit and vegetables",
					"Manufacture of oils and fats",
					"Manufacture of margarine and similar edible fats",
					"Liquid milk and cream production",
					"Butter and cheese production",
					"Manufacture of other milk products",
					"Manufacture of ice cream",
					"Grain milling",
					"Manufacture of breakfast cereals and cereals-based food",
					"Manufacture of starches and starch products",
					"Manufacture of bread; manufacture of fresh pastry goods and cakes",
					"Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes",
					"Manufacture of macaroni, noodles, couscous and similar farinaceous products",
					"Manufacture of sugar",
					"Manufacture of cocoa and chocolate confectionery",
					"Manufacture of sugar confectionery",
					"Tea processing",
					"Production of coffee and coffee substitutes",
					"Manufacture of condiments and seasonings",
					"Manufacture of prepared meals and dishes",
					"Manufacture of homogenized food preparations and dietetic food",
					"Manufacture of other food products n.e.c.",
					"Manufacture of prepared feeds for farm animals",
					"Manufacture of prepared pet foods",
					"Distilling, rectifying and blending of spirits",
					"Manufacture of wine from grape",
					"Manufacture of cider and other fruit wines",
					"Manufacture of other non-distilled fermented beverages",
					"Manufacture of beer",
					"Manufacture of malt",
					"Manufacture of soft drinks; production of mineral waters and other bottled waters",
					"Manufacture of tobacco products",
					"Preparation and spinning of textile fibres",
					"Weaving of textiles",
					"Finishing of textiles",
					"Manufacture of knitted and crocheted fabrics",
					"Manufacture of soft furnishings",
					"manufacture of canvas goods, sacks, etc.",
					"manufacture of household textiles",
					"Manufacture of woven or tufted carpets and rugs",
					"Manufacture of other carpets and rugs",
					"Manufacture of cordage, rope, twine and netting",
					"Manufacture of non-wovens and articles made from non-wovens, except apparel",
					"Manufacture of other technical and industrial textiles",
					"Manufacture of other textiles n.e.c.",
					"Manufacture of leather clothes",
					"Manufacture of workwear",
					"Manufacture of other men's outerwear",
					"Manufacture of other women's outerwear",
					"Manufacture of men's underwear",
					"Manufacture of women's underwear",
					"Manufacture of other wearing apparel and accessories n.e.c.",
					"Manufacture of articles of fur",
					"Manufacture of knitted and crocheted hosiery",
					"Manufacture of other knitted and crocheted apparel",
					"Tanning and dressing of leather; dressing and dyeing of fur",
					"Manufacture of luggage, handbags and the like, saddlery and harness",
					"Manufacture of footwear",
					"Sawmilling and planing of wood",
					"Manufacture of veneer sheets and wood-based panels",
					"Manufacture of assembled parquet floors",
					"Manufacture of other builders' carpentry and joinery",
					"Manufacture of wooden containers",
					"Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials",
					"Manufacture of pulp",
					"Manufacture of paper and paperboard",
					"Manufacture of corrugated paper and paperboard, sacks and bags",
					"Manufacture of other paper and paperboard containers",
					"Manufacture of household and sanitary goods and of toilet requisites",
					"Manufacture of paper stationery",
					"Manufacture of wallpaper",
					"Manufacture of other articles of paper and paperboard n.e.c.",
					"Printing of newspapers",
					"Manufacture of printed labels",
					"Printing n.e.c.",
					"Pre-press and pre-media services",
					"Binding and related services",
					"Reproduction of sound recording",
					"Reproduction of video recording",
					"Reproduction of computer media",
					"Manufacture of coke oven products",
					"Mineral oil refining",
					"Other treatment of petroleum products (excluding petrochemicals manufacture)",
					"Manufacture of industrial gases",
					"Manufacture of dyes and pigments",
					"Manufacture of other inorganic basic chemicals",
					"Manufacture of other organic basic chemicals",
					"Manufacture of fertilizers and nitrogen compounds",
					"Manufacture of plastics in primary forms",
					"Manufacture of synthetic rubber in primary forms",
					"Manufacture of pesticides and other agrochemical products",
					"Manufacture of paints, varnishes and similar coatings, mastics and sealants",
					"Manufacture of printing ink",
					"Manufacture of soap and detergents",
					"Manufacture of cleaning and polishing preparations",
					"Manufacture of perfumes and toilet preparations",
					"Manufacture of explosives",
					"Manufacture of glues",
					"Manufacture of essential oils",
					"Manufacture of other chemical products n.e.c.",
					"Manufacture of man-made fibres",
					"Manufacture of basic pharmaceutical products",
					"Manufacture of pharmaceutical preparations",
					"Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres",
					"Manufacture of other rubber products",
					"Manufacture of plastic plates, sheets, tubes and profiles",
					"Manufacture of plastic packing goods",
					"Manufacture of builders ware of plastic",
					"Manufacture of other plastic products",
					"Manufacture of flat glass",
					"Shaping and processing of flat glass",
					"Manufacture of hollow glass",
					"Manufacture of glass fibres",
					"Manufacture and processing of other glass, including technical glassware",
					"Manufacture of refractory products",
					"Manufacture of ceramic tiles and flags",
					"Manufacture of bricks, tiles and construction products, in baked clay",
					"Manufacture of ceramic household and ornamental articles",
					"Manufacture of ceramic sanitary fixtures",
					"Manufacture of ceramic insulators and insulating fittings",
					"Manufacture of other technical ceramic products",
					"Manufacture of other ceramic products n.e.c.",
					"Manufacture of cement",
					"Manufacture of lime and plaster",
					"Manufacture of concrete products for construction purposes",
					"Manufacture of plaster products for construction purposes",
					"Manufacture of ready-mixed concrete",
					"Manufacture of mortars",
					"Manufacture of fibre cement",
					"Manufacture of other articles of concrete, plaster and cement",
					"Cutting, shaping and finishing of stone",
					"Production of abrasive products",
					"Manufacture of other non-metallic mineral products n.e.c.",
					"Manufacture of basic iron and steel and of ferro-alloys",
					"Manufacture of tubes, pipes, hollow profiles and related fittings, of steel",
					"Cold drawing of bars",
					"Cold rolling of narrow strip",
					"Cold forming or folding",
					"Cold drawing of wire",
					"Precious metals production",
					"Aluminium production",
					"Lead, zinc and tin production",
					"Copper production",
					"Other non-ferrous metal production",
					"Processing of nuclear fuel",
					"Casting of iron",
					"Casting of steel",
					"Casting of light metals",
					"Casting of other non-ferrous metals",
					"Manufacture of metal structures and parts of structures",
					"Manufacture of doors and windows of metal",
					"Manufacture of central heating radiators and boilers",
					"Manufacture of other tanks, reservoirs and containers of metal",
					"Manufacture of steam generators, except central heating hot water boilers",
					"Manufacture of weapons and ammunition",
					"Forging, pressing, stamping and roll-forming of metal; powder metallurgy",
					"Treatment and coating of metals",
					"Machining",
					"Manufacture of cutlery",
					"Manufacture of locks and hinges",
					"Manufacture of tools",
					"Manufacture of steel drums and similar containers",
					"Manufacture of light metal packaging",
					"Manufacture of wire products, chain and springs",
					"Manufacture of fasteners and screw machine products",
					"Manufacture of other fabricated metal products n.e.c.",
					"Manufacture of electronic components",
					"Manufacture of loaded electronic boards",
					"Manufacture of computers and peripheral equipment",
					"Manufacture of telegraph and telephone apparatus and equipment",
					"Manufacture of communication equipment other than telegraph, and telephone apparatus and equipment",
					"Manufacture of consumer electronics",
					"Manufacture of electronic measuring, testing etc. equipment, not for industrial process control",
					"Manufacture of electronic industrial process control equipment",
					"Manufacture of non-electronic measuring, testing etc. equipment, not for industrial process control",
					"Manufacture of non-electronic industrial process control equipment",
					"Manufacture of watches and clocks",
					"Manufacture of irradiation, electromedical and electrotherapeutic equipment",
					"Manufacture of optical precision instruments",
					"Manufacture of photographic and cinematographic equipment",
					"Manufacture of magnetic and optical media",
					"Manufacture of electric motors, generators and transformers",
					"Manufacture of electricity distribution and control apparatus",
					"Manufacture of batteries and accumulators",
					"Manufacture of fibre optic cables",
					"Manufacture of other electronic and electric wires and cables",
					"Manufacture of wiring devices",
					"Manufacture of electric lighting equipment",
					"Manufacture of electric domestic appliances",
					"Manufacture of non-electric domestic appliances",
					"Manufacture of other electrical equipment",
					"Manufacture of engines and turbines, except aircraft, vehicle and cycle engines",
					"Manufacture of fluid power equipment",
					"Manufacture of pumps",
					"Manufacture of compressors",
					"Manufacture of taps and valves",
					"Manufacture of bearings, gears, gearing and driving elements",
					"Manufacture of ovens, furnaces and furnace burners",
					"Manufacture of lifting and handling equipment",
					"Manufacture of office machinery and equipment (except computers and peripheral equipment)",
					"Manufacture of power-driven hand tools",
					"Manufacture of non-domestic cooling and ventilation equipment",
					"Manufacture of other general-purpose machinery n.e.c.",
					"Manufacture of agricultural tractors",
					"Manufacture of agricultural and forestry machinery other than tractors",
					"Manufacture of metal forming machinery",
					"Manufacture of other machine tools",
					"Manufacture of machinery for metallurgy",
					"Manufacture of machinery for mining",
					"Manufacture of earthmoving equipment",
					"Manufacture of equipment for concrete crushing and screening and roadworks",
					"Manufacture of machinery for food, beverage and tobacco processing",
					"Manufacture of machinery for textile, apparel and leather production",
					"Manufacture of machinery for paper and paperboard production",
					"Manufacture of plastics and rubber machinery",
					"Manufacture of other special-purpose machinery n.e.c.",
					"Manufacture of motor vehicles",
					"Manufacture of bodies (coachwork) for motor vehicles (except caravans)",
					"Manufacture of trailers and semi-trailers",
					"Manufacture of caravans",
					"Manufacture of electrical and electronic equipment for motor vehicles and their engines",
					"Manufacture of other parts and accessories for motor vehicles",
					"Building of ships and floating structures",
					"Building of pleasure and sporting boats",
					"Manufacture of railway locomotives and rolling stock",
					"Manufacture of air and spacecraft and related machinery",
					"Manufacture of military fighting vehicles",
					"Manufacture of motorcycles",
					"Manufacture of bicycles and invalid carriages",
					"Manufacture of other transport equipment n.e.c.",
					"Manufacture of office and shop furniture",
					"Manufacture of kitchen furniture",
					"Manufacture of mattresses",
					"Manufacture of other furniture",
					"Striking of coins",
					"Manufacture of jewellery and related articles",
					"Manufacture of imitation jewellery and related articles",
					"Manufacture of musical instruments",
					"Manufacture of sports goods",
					"Manufacture of professional and arcade games and toys",
					"Manufacture of other games and toys, n.e.c.",
					"Manufacture of medical and dental instruments and supplies",
					"Manufacture of brooms and brushes",
					"Other manufacturing n.e.c.",
					"Repair of fabricated metal products",
					"Repair of machinery",
					"Repair of electronic and optical equipment",
					"Repair of electrical equipment",
					"Repair and maintenance of ships and boats",
					"Repair and maintenance of aircraft and spacecraft",
					"Repair and maintenance of other transport equipment n.e.c.",
					"Repair of other equipment",
					"Installation of industrial machinery and equipment"
				]
			},
	
			{
				"category": "Electricity, gas, steam and air conditioning supply",
				"classifcations": [
					"Production of electricity",
					"Transmission of electricity",
					"Distribution of electricity",
					"Trade of electricity",
					"Manufacture of gas",
					"Distribution of gaseous fuels through mains",
					"Trade of gas through mains",
					"Steam and air conditioning supply"
				]
			},
	
			{
				"category": "Water supply, sewerage, waste management and remediation activities",
				"classifcations": [
					"Water collection, treatment and supply",
					"Sewerage",
					"Collection of non-hazardous waste",
					"Collection of hazardous waste",
					"Treatment and disposal of non-hazardous waste",
					"Treatment and disposal of hazardous waste",
					"Dismantling of wrecks",
					"Recovery of sorted materials",
					"Remediation activities and other waste management services"
				]
			},
	
			{
				"category": "Construction",
				"classifcations": [
					"Development of building projects",
					"Construction of commercial buildings",
					"Construction of domestic buildings",
					"Construction of roads and motorways",
					"Construction of railways and underground railways",
					"Construction of bridges and tunnels",
					"Construction of utility projects for fluids",
					"Construction of utility projects for electricity and telecommunications",
					"Construction of water projects",
					"Construction of other civil engineering projects n.e.c.",
					"Demolition",
					"Site preparation",
					"Test drilling and boring",
					"Electrical installation",
					"Plumbing, heat and air-conditioning installation",
					"Other construction installation",
					"Plastering",
					"Joinery installation",
					"Floor and wall covering",
					"Painting",
					"Glazing",
					"Other building completion and finishing",
					"Roofing activities",
					"Scaffold erection",
					"Other specialised construction activities n.e.c."
				]
			},
	
			{
				"category": "Wholesale and retail trade; repair of motor vehicles and motorcycles",
				"classifcations": [
					"Sale of new cars and light motor vehicles",
					"Sale of used cars and light motor vehicles",
					"Sale of other motor vehicles",
					"Maintenance and repair of motor vehicles",
					"Wholesale trade of motor vehicle parts and accessories",
					"Retail trade of motor vehicle parts and accessories",
					"Sale, maintenance and repair of motorcycles and related parts and accessories",
					"Agents selling agricultural raw materials, livestock, textile raw materials and semi-finished goods",
					"Agents involved in the sale of fuels, ores, metals and industrial chemicals",
					"Agents involved in the sale of timber and building materials",
					"Agents involved in the sale of machinery, industrial equipment, ships and aircraft",
					"Agents involved in the sale of furniture, household goods, hardware and ironmongery",
					"Agents involved in the sale of textiles, clothing, fur, footwear and leather goods",
					"Agents involved in the sale of food, beverages and tobacco",
					"Agents specialised in the sale of other particular products",
					"Agents involved in the sale of a variety of goods",
					"Wholesale of grain, unmanufactured tobacco, seeds and animal feeds",
					"Wholesale of flowers and plants",
					"Wholesale of live animals",
					"Wholesale of hides, skins and leather",
					"Wholesale of fruit and vegetables",
					"Wholesale of meat and meat products",
					"Wholesale of dairy products, eggs and edible oils and fats",
					"Wholesale of fruit and vegetable juices, mineral water and soft drinks",
					"Wholesale of wine, beer, spirits and other alcoholic beverages",
					"Wholesale of tobacco products",
					"Wholesale of sugar and chocolate and sugar confectionery",
					"Wholesale of coffee, tea, cocoa and spices",
					"Wholesale of other food, including fish, crustaceans and molluscs",
					"Non-specialised wholesale of food, beverages and tobacco",
					"Wholesale of textiles",
					"Wholesale of clothing and footwear",
					"Wholesale of audio tapes, records, CDs and video tapes and the equipment on which these are played",
					"Wholesale of radio, television goods & electrical household appliances (other than records, tapes, CD's & video tapes and the equipment used for playing them)",
					"Wholesale of china and glassware and cleaning materials",
					"Wholesale of perfume and cosmetics",
					"Wholesale of pharmaceutical goods",
					"Wholesale of furniture, carpets and lighting equipment",
					"Wholesale of watches and jewellery",
					"Wholesale of musical instruments",
					"Wholesale of household goods (other than musical instruments) n.e.c",
					"Wholesale of computers, computer peripheral equipment and software",
					"Wholesale of electronic and telecommunications equipment and parts",
					"Wholesale of agricultural machinery, equipment and supplies",
					"Wholesale of machine tools",
					"Wholesale of mining, construction and civil engineering machinery",
					"Wholesale of machinery for the textile industry and of sewing and knitting machines",
					"Wholesale of office furniture",
					"Wholesale of other office machinery and equipment",
					"Wholesale of other machinery and equipment",
					"Wholesale of petroleum and petroleum products",
					"Wholesale of other fuels and related products",
					"Wholesale of metals and metal ores",
					"Wholesale of wood, construction materials and sanitary equipment",
					"Wholesale of hardware, plumbing and heating equipment and supplies",
					"Wholesale of chemical products",
					"Wholesale of other intermediate products",
					"Wholesale of waste and scrap",
					"Non-specialised wholesale trade",
					"Retail sale in non-specialised stores with food, beverages or tobacco predominating",
					"Other retail sale in non-specialised stores",
					"Retail sale of fruit and vegetables in specialised stores",
					"Retail sale of meat and meat products in specialised stores",
					"Retail sale of fish, crustaceans and molluscs in specialised stores",
					"Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores",
					"Retail sale of beverages in specialised stores",
					"Retail sale of tobacco products in specialised stores",
					"Other retail sale of food in specialised stores",
					"Retail sale of automotive fuel in specialised stores",
					"Retail sale of computers, peripheral units and software in specialised stores",
					"Retail sale of mobile telephones",
					"Retail sale of telecommunications equipment other than mobile telephones",
					"Retail sale of audio and video equipment in specialised stores",
					"Retail sale of textiles in specialised stores",
					"Retail sale of hardware, paints and glass in specialised stores",
					"Retail sale of carpets, rugs, wall and floor coverings in specialised stores",
					"Retail sale of electrical household appliances in specialised stores",
					"Retail sale of musical instruments and scores",
					"Retail of furniture, lighting, and similar (not musical instruments or scores) in specialised store",
					"Retail sale of books in specialised stores",
					"Retail sale of newspapers and stationery in specialised stores",
					"Retail sale of music and video recordings in specialised stores",
					"Retail sale of sports goods, fishing gear, camping goods, boats and bicycles",
					"Retail sale of games and toys in specialised stores",
					"Retail sale of clothing in specialised stores",
					"Retail sale of footwear in specialised stores",
					"Retail sale of leather goods in specialised stores",
					"Dispensing chemist in specialised stores",
					"Retail sale of hearing aids",
					"Retail sale of medical and orthopaedic goods in specialised stores (not incl. hearing aids) n.e.c.",
					"Retail sale of cosmetic and toilet articles in specialised stores",
					"Retail sale of flowers, plants, seeds, fertilizers, pet animals and pet food in specialised stores",
					"Retail sale of watches and jewellery in specialised stores",
					"Retail sale in commercial art galleries",
					"Retail sale by opticians",
					"Other retail sale of new goods in specialised stores (not commercial art galleries and opticians)",
					"Retail sale of antiques including antique books in stores",
					"Retail sale of other second-hand goods in stores (not incl. antiques)",
					"Retail sale via stalls and markets of food, beverages and tobacco products",
					"Retail sale via stalls and markets of textiles, clothing and footwear",
					"Retail sale via stalls and markets of other goods",
					"Retail sale via mail order houses or via Internet",
					"Other retail sale not in stores, stalls or markets"
				]
			},
	
			{
				"category": "Transportation and storage",
				"classifcations": [
					"Passenger rail transport, interurban",
					"Freight rail transport",
					"Urban and suburban passenger railway transportation by underground, metro and similar systems",
					"Other urban, suburban or metropolitan passenger land transport (not underground, metro or similar)",
					"Taxi operation",
					"Other passenger land transport",
					"Freight transport by road",
					"Removal services",
					"Transport via pipeline",
					"Sea and coastal passenger water transport",
					"Sea and coastal freight water transport",
					"Inland passenger water transport",
					"Inland freight water transport",
					"Scheduled passenger air transport",
					"Non-scheduled passenger air transport",
					"Freight air transport",
					"Space transport",
					"Operation of warehousing and storage facilities for water transport activities",
					"Operation of warehousing and storage facilities for air transport activities",
					"Operation of warehousing and storage facilities for land transport activities",
					"Operation of rail freight terminals",
					"Operation of rail passenger facilities at railway stations",
					"Operation of bus and coach passenger facilities at bus and coach stations",
					"Other service activities incidental to land transportation, n.e.c.",
					"Service activities incidental to water transportation",
					"Service activities incidental to air transportation",
					"Cargo handling for water transport activities",
					"Cargo handling for air transport activities",
					"Cargo handling for land transport activities",
					"Other transportation support activities",
					"Postal activities under universal service obligation",
					"Licensed carriers",
					"Unlicensed carriers"
				]
			},
	
			{
				"category": "Accommodation and food service activities				",
				"classifcations": [
					"Hotels and similar accommodation",
					"Holiday centres and villages",
					"Youth hostels",
					"Other holiday and other collective accommodation",
					"Recreational vehicle parks, trailer parks and camping grounds",
					"Other accommodation",
					"Licenced restaurants",
					"Unlicenced restaurants and cafes",
					"Take-away food shops and mobile food stands",
					"Event catering activities",
					"Other food services",
					"Licenced clubs",
					"Public houses and bars"
				]
			},
	
			{
				"category": "Information and communication",
				"classifcations": [
					"Book publishing",
					"Publishing of directories and mailing lists",
					"Publishing of newspapers",
					"Publishing of learned journals",
					"Publishing of consumer and business journals and periodicals",
					"Other publishing activities",
					"Publishing of computer games",
					"Other software publishing",
					"Motion picture production activities",
					"Video production activities",
					"Television programme production activities",
					"Motion picture, video and television programme post-production activities",
					"Motion picture distribution activities",
					"Video distribution activities",
					"Television programme distribution activities",
					"Motion picture projection activities",
					"Sound recording and music publishing activities",
					"Radio broadcasting",
					"Television programming and broadcasting activities",
					"Wired telecommunications activities",
					"Wireless telecommunications activities",
					"Satellite telecommunications activities",
					"Other telecommunications activities",
					"Ready-made interactive leisure and entertainment software development",
					"Business and domestic software development",
					"Information technology consultancy activities",
					"Computer facilities management activities",
					"Other information technology service activities",
					"Data processing, hosting and related activities",
					"Web portals",
					"News agency activities",
					"Other information service activities n.e.c."
				]
			},
	
			{
				"category": "Financial and insurance activities",
				"classifcations": [
					"Central banking",
					"Banks",
					"Building societies",
					"Activities of agricultural holding companies",
					"Activities of production holding companies",
					"Activities of construction holding companies",
					"Activities of distribution holding companies",
					"Activities of financial services holding companies",
					"Activities of other holding companies n.e.c.",
					"Activities of investment trusts",
					"Activities of unit trusts",
					"Activities of venture and development capital companies",
					"Activities of open-ended investment companies",
					"Activities of property unit trusts",
					"Activities of real estate investment trusts",
					"Financial leasing",
					"Credit granting by non-deposit taking finance houses and other specialist consumer credit grantors",
					"Activities of mortgage finance companies",
					"Other credit granting n.e.c.",
					"Security dealing on own account",
					"Factoring",
					"Financial intermediation not elsewhere classified",
					"Life insurance",
					"Non-life insurance",
					"Life reinsurance",
					"Non-life reinsurance",
					"Pension funding",
					"Administration of financial markets",
					"Security and commodity contracts dealing activities",
					"Activities auxiliary to financial intermediation n.e.c.",
					"Risk and damage evaluation",
					"Activities of insurance agents and brokers",
					"Other activities auxiliary to insurance and pension funding",
					"Fund management activities"
				]
			},
	
			{
				"category": "Real estate activities",
				"classifcations": [
					"Buying and selling of own real estate",
					"Renting and operating of Housing Association real estate",
					"Letting and operating of conference and exhibition centres",
					"Other letting and operating of own or leased real estate",
					"Real estate agencies",
					"Management of real estate on a fee or contract basis"
				]
			},
	
			{
				"category": "Professional, scientific and technical activities",
				"classifcations": [
					"Barristers at law",
					"Solicitors",
					"Activities of patent and copyright agents; other legal activities n.e.c.",
					"Accounting and auditing activities",
					"Bookkeeping activities",
					"Tax consultancy",
					"Activities of head offices",
					"Public relations and communications activities",
					"Financial management",
					"Management consultancy activities other than financial management",
					"Architectural activities",
					"Urban planning and landscape architectural activities",
					"Engineering design activities for industrial process and production",
					"Engineering related scientific and technical consulting activities",
					"Other engineering activities",
					"Technical testing and analysis",
					"Research and experimental development on biotechnology",
					"Other research and experimental development on natural sciences and engineering",
					"Research and experimental development on social sciences and humanities",
					"Advertising agencies",
					"Media representation services",
					"Market research and public opinion polling",
					"specialised design activities",
					"Portrait photographic activities",
					"Other specialist photography",
					"Film processing",
					"Photographic activities not elsewhere classified",
					"Translation and interpretation activities",
					"Environmental consulting activities",
					"Quantity surveying activities",
					"Other professional, scientific and technical activities n.e.c.",
					"Non-trading company",
					"Veterinary activities"
				]
			},
	
			{
				"category": "Administrative and support service activities",
				"classifcations": [
					"Renting and leasing of cars and light motor vehicles",
					"Renting and leasing of trucks and other heavy vehicles",
					"Renting and leasing of recreational and sports goods",
					"Renting of video tapes and disks",
					"Renting and leasing of media entertainment equipment",
					"Renting and leasing of other personal and household goods",
					"Renting and leasing of agricultural machinery and equipment",
					"Renting and leasing of construction and civil engineering machinery and equipment",
					"Renting and leasing of office machinery and equipment (including computers)",
					"Renting and leasing of passenger water transport equipment",
					"Renting and leasing of freight water transport equipment",
					"Renting and leasing of air passenger transport equipment",
					"Renting and leasing of freight air transport equipment",
					"Renting and leasing of other machinery, equipment and tangible goods n.e.c.",
					"Leasing of intellectual property and similar products, except copyright works",
					"Motion picture, television and other theatrical casting activities",
					"Other activities of employment placement agencies",
					"Temporary employment agency activities",
					"Human resources provision and management of human resources functions",
					"Travel agency activities",
					"Tour operator activities",
					"Activities of tourist guides",
					"Other reservation service activities n.e.c.",
					"Private security activities",
					"Security systems service activities",
					"Investigation activities",
					"Combined facilities support activities",
					"General cleaning of buildings",
					"Window cleaning services",
					"Specialised cleaning services",
					"Furnace and chimney cleaning services",
					"Other building and industrial cleaning activities",
					"Disinfecting and exterminating services",
					"Other cleaning services",
					"Landscape service activities",
					"Combined office administrative service activities",
					"Photocopying, document preparation and other specialised office support activities",
					"Activities of call centres",
					"Activities of exhibition and fair organisers",
					"Activities of conference organisers",
					"Activities of collection agencies",
					"Activities of credit bureaus",
					"Packaging activities",
					"Other business support service activities n.e.c."
				]
			},
	
			{
				"category": "Public administration and defence; compulsory social security",
				"classifcations": [
					"General public administration activities",
					"Regulation of health care, education, cultural and other social services, not incl. social security",
					"Regulation of and contribution to more efficient operation of businesses",
					"Foreign affairs",
					"Defence activities",
					"Justice and judicial activities",
					"Public order and safety activities",
					"Fire service activities",
					"Compulsory social security activities"
				]
			},
	
			{
				"category": "Education",
				"classifcations": [
					"Pre-primary education",
					"Primary education",
					"General secondary education",
					"Technical and vocational secondary education",
					"Post-secondary non-tertiary education",
					"First-degree level higher education",
					"Post-graduate level higher education",
					"Sports and recreation education",
					"Cultural education",
					"Driving school activities",
					"Other education n.e.c.",
					"Educational support services"
				]
			},
	
			{
				"category": "Human health and social work activities",
				"classifcations": [
					"Hospital activities",
					"Medical nursing home activities",
					"General medical practice activities",
					"Specialists medical practice activities",
					"Dental practice activities",
					"Other human health activities",
					"Residential nursing care facilities",
					"Residential care activities for learning difficulties, mental health and substance abuse",
					"Residential care activities for the elderly and disabled",
					"Other residential care activities n.e.c.",
					"Social work activities without accommodation for the elderly and disabled",
					"Child day-care activities",
					"Other social work activities without accommodation n.e.c."
				]
			},
	
			{
				"category": "Arts, entertainment and recreation",
				"classifcations": [
					"Performing arts",
					"Support activities to performing arts",
					"Artistic creation",
					"Operation of arts facilities",
					"Library activities",
					"Archives activities",
					"Museums activities",
					"Operation of historical sites and buildings and similar visitor attractions",
					"Botanical and zoological gardens and nature reserves activities",
					"Gambling and betting activities",
					"Operation of sports facilities",
					"Activities of sport clubs",
					"Fitness facilities",
					"Activities of racehorse owners",
					"Other sports activities",
					"Activities of amusement parks and theme parks",
					"Other amusement and recreation activities n.e.c."
				]
			},
	
			{
				"category": "Other service activities",
				"classifcations": [
					"Activities of business and employers membership organisations",
					"Activities of professional membership organisations",
					"Activities of trade unions",
					"Activities of religious organisations",
					"Activities of political organisations",
					"Activities of other membership organisations n.e.c.",
					"Repair of computers and peripheral equipment",
					"Repair of communication equipment",
					"Repair of consumer electronics",
					"Repair of household appliances and home and garden equipment",
					"Repair of footwear and leather goods",
					"Repair of furniture and home furnishings",
					"Repair of watches, clocks and jewellery",
					"Repair of personal and household goods n.e.c.",
					"Washing and (dry-)cleaning of textile and fur products",
					"Hairdressing and other beauty treatment",
					"Funeral and related activities",
					"Physical well-being activities",
					"Other service activities n.e.c."
				]
			},
	
			{
				"category": "Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use",
				"classifcations": [
					"Activities of households as employers of domestic personnel",
					"Residents property management",
					"Undifferentiated goods-producing activities of private households for own use",
					"Undifferentiated service-producing activities of private households for own use"
				]
			},
	
			{
				"category": "Activities of extraterritorial organisations and bodies",
				"classifcations": [
					"Activities of extraterritorial organisations and bodies",
					"Dormant Company"
				]
			}
		]
	}

	const formattedData = businessClassifications.classifications.flatMap(category => 
		category.classifcations.map(label => ({
			category: category.category,
			label: label
		}))
	);

    return (
		<>
			<FormLabel>Industry</FormLabel>
			<Autocomplete
				options={formattedData}
				groupBy={(option) => option.category}
				getOptionLabel={(option) => option.label}
				sx={{ width: 300 }}
			/>
		</>
	);
}

export default IndustrySelect;


// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
// TODO: Pull categories from companies house
