const { useState, useEffect } = require("react");
const { useSearchParams } = require("react-router-dom");

const { auth } = require('remote/firebase');

const Results = () => {
    const [searchParams, _] = useSearchParams();
    const [searchResults, setSearchResults] = useState({ people: [] });

    const user = auth.currentUser;

    const performSearch = async (query) => {
        const token = await user.getIdToken();

        const response = await fetch(`https://django-api-154302460370.europe-west1.run.app/api/search?q=${query}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        // console.log({
        //     'Accept': 'application/json',
        //     'Authorization': `Bearer ${token}`,
        // });
        console.log(response)
        if (response.status === 200) {
            const data = await response.json();
            console.log(data)
            setSearchResults(data.results || { people: [] });
        }
        else {
            // TODO: error snackbar
            console.log("We fucked up here pal. Sorry!")
        }
    };

    useEffect(() => {
        performSearch(searchParams.get("q"));
    }, [searchParams]);

    return (
        <div>
            {searchResults && searchResults.length > 0 ? (
                <table border="1">
                    <thead>
                        <tr>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults.people.map((person, index) => (
                            <tr key={index}>
                                <td>{person.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No results found. Click <a href="/">here</a> to search again.</p>
            )}
        </div>
    );
};

export default Results;