import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Stack, Typography, Input, Button, Snackbar } from '@mui/joy';

// import { getUserDocument } from 'remote/firestore'

import { getFirebaseIdToken } from 'remote/auth';

import IndustrySelect from './IndustrySelect'

const ConnectCompanyInformationOnboarding = () => {
    const [loading, setLoading] = useState(true);
    
    // Things we can populate from their linkedin
    
    const [companyName, setCompanyName] = useState(''); // This is a select from their linkedIn. Only once this has been confirmed will we show the other properties

    // const [numberOfEmployees, setNumberOfEmployees] = useState('');
    // const [headquartersLocation, setHeadquartersLocation] = useState('');
    // const [companyDescription, setCompanyDescription] = useState('');
    // const [userJobRole, setUserJobRole] = useState('');
    // const [companyWebsite, setCompanyWebsite] = useState(''); // initially pulled from email

    // Things that the user has to input themselves
    const [industry, setIndustry] = useState('');
    // const [companyNumber, setCompanyNumber] = useState(''); // TODO: Validate against Companies House
    // const [companyMission, setCompanyMission] = useState('');
    // const [companyVision, setCompanyVision] = useState('');
    
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // const navigate = useNavigate();

    // Getting linkedin info
    // const getUnipileId = async () => {
    //     const userDoc = getUserDocument();
    //     if (userDoc.unipileId)
    //         return userDoc.unipileId
    
    //     return null;
    // };

    // const fetchOwnProfile = async () => {
    //     const unipileId = await getUnipileId();

    // }

    const getInitialUserCompanyData = async () => {
        setLoading(true);
        try {
            // Get the Firebase ID token
            const idToken = await getFirebaseIdToken();
            console.log("Firebase JWT:", idToken);

            // Make the fetch request to your API
            const response = await fetch('http://https://django-api-154302460370.europe-west1.run.app/api/protected/', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + idToken,
                    'Content-Type': 'application/json'
                }
            });
            
            const data = await response.json();
            console.log(data);
            // setUserData(data); // Store the data in the state

        } catch (error) {
            console.error('Error:', error);
            setErrorMessage(error);
        } finally {
            setLoading(false); // Set loading to false in both success and error cases
        }
    };

    // useEffect to run the fetch function only when the component mounts or idToken changes
    useEffect(() => {
        getInitialUserCompanyData();
    }, []); 

    const handleSubmit = async (e) => {
        e.preventDefault();

        // const companyData = {
        //     companyName,
        //     industry,
        //     numberOfEmployees,
        //     headquartersLocation,
        //     description,
        // };

        // try {
        //     const response = await fetch('https://django-api-154302460370.europe-west1.run.app/api/company/employees/', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify(companyData),
        //     });

        //     if (!response.ok) {
        //         throw new Error('Failed to submit company information');
        //     }

        //     console.log("Company information submitted!");
        //     navigate('/company-dashboard');
        // } catch (error) {
        //     console.error("Failed to submit company information:", error);
        //     setErrorMessage(error.message);
        //     setSnackBarOpen(true);
        // }
    };

    return (
        <>
            {loading ? 
                <Stack spacing={2}>
                    <h1>Loading...</h1> 
                    <p>This will take around 1 minute to complete. Please do not refresh the page or navigate away.</p>
                </Stack>
            :
                <form onSubmit={handleSubmit}>
                    <Stack spacing={3} sx={{ maxWidth: '500px', margin: '0 auto', padding: '20px' }}>
                        <Typography level="h1" component="h1">
                            Company Information
                        </Typography>

                        <Input
                            label="Company Name"
                            placeholder="Enter the company name"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            required
                        />
                        {companyName !== '' && <>
                            <IndustrySelect industry={industry} setIndustry={setIndustry}/>

                            {/* <Input
                                label="Number of Employees"
                                placeholder="Enter the number of employees"
                                type="number"
                                value={numberOfEmployees}
                                onChange={(e) => setNumberOfEmployees(e.target.value)}
                                required
                            />

                            <Input
                                label="Headquarters Location"
                                placeholder="Enter the headquarters location"
                                value={headquartersLocation}
                                onChange={(e) => setHeadquartersLocation(e.target.value)}
                            />

                            <Input
                                label="Description"
                                placeholder="Enter a brief description of the company"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                multiline
                                rows={4}
                            />

                            <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar src={logoURL} alt="Company Logo" sx={{ width: 80, height: 80 }} />
                                <Button variant="outlined" component="label">
                                    Upload Company Logo
                                    <input type="file" accept="image/*" hidden onChange={handleLogoURLChange} />
                                </Button>
                            </Stack> */}

                            <Button type="submit" sx={{ marginTop: '20px' }}>
                                Save Company Info
                            </Button>
                        </>}
                    </Stack>

                    <Snackbar
                        variant="solid"
                        color="danger"
                        size="md"
                        open={snackBarOpen}
                        onClose={() => setSnackBarOpen(false)}
                    >
                        {errorMessage}
                    </Snackbar>
                </form>
            }
        </>
    );
};

export default ConnectCompanyInformationOnboarding;
