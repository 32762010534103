// import { functions } from "remote/firebase";
// import { httpsCallable } from 'firebase/functions'
import { auth } from 'remote/firebase';

import { Button, Stack, Snackbar} from '@mui/joy';

import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const ConnectLinkedInOnboarding = () => {

    const [searchParams, _] = useSearchParams()
    const successful_load = searchParams.get("success") // TODO: Turn this result into a bool

    const [enabled, setButtonEnabled] = useState(true);
        
    const handleConnectLinkedIn = async () => {
        setButtonEnabled(false);
        try {
            const user = auth.currentUser;

            if (!user) {
                throw new Error("User is not authenticated");
            }

            // Get the ID token for the authenticated user
            const idToken = await user.getIdToken();
            // console.log(idToken)

            // Make the POST request to your custom endpoint
            const response = await fetch('https://connectlinkedin-inbnrj5zca-ew.a.run.app', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Content-Type': 'application/json'
                },  
                body: JSON.stringify({data:{}})  // Include any necessary data in the body
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data);
            if(data.result.url)
                window.location.href = data.result.url;
            else {
                throw new Error('URL not received from the server');
            }
        } catch (error) {
            // TODO: Show snack bar with error message
            console.error('Error connecting to LinkedIn:', error.message);
        }
        finally {
            setButtonEnabled(true);
        }
    };

    return (
        <>
            <Stack>
                <h1>Connect to LinkedIn</h1>
                <p>For this to work, we need a starting point to know who <i>you</i> are.</p>
                <Button
                    enabled={enabled.toString()}
                    variant="solid"
                    color="primary"
                    onClick={handleConnectLinkedIn}
                    sx={{ marginTop: '20px' }}
                >
                    Connect
                </Button>
            </Stack>
            <Snackbar open={successful_load === "false"} color="danger" variant="solid">
                <p>Failed to connect LinkedIn. Please try again.</p>
            </Snackbar>
        </>
    );

};

export default ConnectLinkedInOnboarding;