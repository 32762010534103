const NoPage = () => {
  return(
    <div>
      <h1>Oops: 404! The page you're looking for isn't here...</h1>
      <h2>But it definitely was, right?</h2>
      <p>Maybe you just imagined that link? It's strange, though — this page was definitely here just a moment ago. Are you sure you typed the address correctly? We wouldn't have sent you somewhere that didn't exist if you clicked on a link on our site. It feels like we've been through this before and you keep ending up here.</p>
      <p>It's probably just your memory playing tricks on you. You have been remembering things wrong a lot recently! But don't worry, maybe it's better this way; who knows what you would've found if you followed that link!</p>
      <p>Why not try refreshing, or take a deep breath and double-check? If not, we're sure the page will show up exactly where you expect it… if it was even there to begin with.</p>
    </div>
  );
};

export default NoPage;
