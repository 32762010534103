// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA8olyAaSf12nZNhkHH5zTVr9HpQE1OIt8",
    authDomain: "two-degrees-search.firebaseapp.com",
    projectId: "two-degrees-search",
    storageBucket: "two-degrees-search.appspot.com",
    messagingSenderId: "154302460370",
    appId: "1:154302460370:web:21cb2786089ba1bbfa180b",
    measurementId: "G-SN132BRQ9H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, 'eu-west1');

export {auth, db, functions};