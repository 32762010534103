import { useState, useEffect } from "react";
import { Navigate, Link } from 'react-router-dom'

import Stack from '@mui/joy/Stack';
import Snackbar from '@mui/joy/Snackbar';

import { useAuth } from 'contexts/AuthContext';

import { doSignInWithEmailAndPassword, doSignInWithGoogle } from "remote/auth";
import GoogleSignInButton from "../GoogleSignInButton";

const Login = () => {
    const { userLoggedIn } = useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isSigningIn, setIsSigningIn] = useState(false)
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onSubmit = async (e) => {
        e.preventDefault()
        if(!isSigningIn) {
            setIsSigningIn(true)
            const userCreds = await doSignInWithEmailAndPassword(email, password)
                .catch((error) => {
                    console.log(error);
                    setErrorMessage(String(error));
                    setSnackBarOpen(true);
                })

            if(userCreds)
                console.log(userCreds);
            
            setIsSigningIn(false);

            // doSendEmailVerification()
        }
    }

    const onGoogleSignIn = (e) => {
        e.preventDefault()
        if (!isSigningIn) {
            setIsSigningIn(true)
            doSignInWithGoogle()
                .catch((error) => {
                    console.log(error);
                        setErrorMessage(String(error));
                        setSnackBarOpen(true);
                })
                .then((userCredential) => {
                    console.log(userCredential);
                })
                .finally(() =>{
                    setIsSigningIn(false);
                });
        }
    }

    // close the snack bar after 5 seconds
    useEffect(() => {
        if (snackBarOpen) {
            // If snackBarOpen is true, start a timer to set it to false after 5 seconds
            const timer = setTimeout(() => {
                setSnackBarOpen(false);
            }, 5000);

            // Cleanup the timer if the component unmounts or snackBarOpen changes
            return () => clearTimeout(timer);
        }
    }, [snackBarOpen]);
    
    // const signIn = (event) => {
    //     event.preventDefault();

    //     signInWithEmailAndPassword(auth, email, password)
    //         .then((userCredential) => {
    //             console.log(userCredential);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             setErrorMessage(String(error));
    //             setSnackBarOpen(true);
    //         })
    // }

    return (
        <>
            {userLoggedIn && (<Navigate to={'/'} replace={true} />)}
            <div className="sign-in-container">
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <form>
                        <Stack
                            direction="column"
                            spacing={2}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <h1>Two Degrees</h1>
                            <h2>Log In</h2>
                            <input type="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)}></input>
                            <input type="password" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)}></input>
                            <button type="submit" onClick={onSubmit} disabled={isSigningIn}>Log In</button> 
                        </Stack>   
                    </form>

                    <p>OR</p>

                    <GoogleSignInButton isSigningIn={isSigningIn} onGoogleSignIn={onGoogleSignIn}/>
                    <p>Don't have an account? <Link to={'/auth/signup'}>Sign up</Link></p>

                </Stack>
            </div>
            <Snackbar variant="solid" color="danger" size="md" open={snackBarOpen}>
                {errorMessage}
            </Snackbar>
        </>
    )
};

export default Login;
