import { useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';

export default function SearchBar({ onSearchCallback }) {
    const [query, setQuery] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (query.trim()) {
        onSearchCallback(query);
      }
    };
  
    const onEnterPress = (e) => {
      if(e.keyCode === 13 && e.shiftKey === false) {
        e.preventDefault();
        handleSubmit(e);
      }
    }
  
    const clearInput = () => {
      setQuery('');
    };
  
    return (
      <form className="search-field-container" onSubmit={handleSubmit}>
        <div className="search-bar">
          <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
          </svg>
          <TextareaAutosize
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search..." // Add a placeholder for user guidance
            onKeyDown={onEnterPress}
          />
          {query && 
            <svg className="clear-icon" onClick={clearInput} focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M18 6.41L16.59 5 12 9.59 7.41 5 6 6.41 10.59 11 6 15.59 7.41 17 12 12.41 16.59 17 18 15.59 13.41 11z"></path>
            </svg>
          }
        </div>
        <button type="submit">AI Person Search</button>
      </form>
    );
  }