import { Stack } from "@mui/joy"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { validateAccountInitialised } from "remote/auth";

const InitialisingUserPage = () => {

    const navigate = useNavigate();

    // check if initialisation is complete
    useEffect(() => {
        // Define the function you want to run
        const checkInit = async () => {
            validateAccountInitialised()
                .then((userInitComplete) => {
                    console.log("User init?", userInitComplete);
                    if (userInitComplete) {
                        navigate("/");
                    }
                })
        };
    
        // Set up an interval to run the function every 3 seconds (3000 milliseconds)
        const intervalId = setInterval(checkInit, 3000);
    
        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Stack>
            <h1>Linkedin connected successfully.</h1>
            <h2>Initialising your Two Degrees account.</h2>
            <p>This will take a couple of minutes...</p>
        </Stack>
    )
}

export default InitialisingUserPage;