import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from './firebase'; 

const updateUserDocument = async (updatedData) => {
    try {
        // Get the currently authenticated user
        const user = auth.currentUser;

        if (!user) {
            throw new Error("User is not authenticated");
        }

        // Reference to the user document
        const userDocRef = doc(db, 'users', user.uid);

        // Update the user document
        await updateDoc(userDocRef, updatedData);

        console.log("User document updated successfully");
    } catch (error) {
        console.error("Error updating user document:", error);
        throw error;
    }
};

const getUserDocument = async () => {
    try {
        // Get the currently authenticated user
        const user = auth.currentUser;

        if (!user) {
            throw new Error("User is not authenticated");
        }

        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
            const data = userDocSnap.data()
            console.log("Document data:", data);
            return data;
        } else {
            throw new Error("Somehow the user document does not exist. UID: ", user.uid);
        }
    } catch (error) {
        console.log("Error getting document:", error);
        throw error;
    }
}

export { updateUserDocument, getUserDocument };