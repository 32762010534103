import { Outlet, Link, useNavigate } from "react-router-dom";
import { useAuth } from 'contexts/AuthContext';
import { doSignOut } from "remote/auth";

import 'assets/app.scss';


const Layout = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth()
  return (
    <>
      {currentUser && 
        <nav>
          <p>Welcome back, {currentUser.displayName && currentUser.displayName}</p>
          <ul>
            <li>
              <Link to="/">Search</Link>
            </li>
            <li onClick={() => { doSignOut().then(() => navigate('/auth'))}}>
              <Link>Log out</Link>
            </li>
          </ul>
        </nav>
      }

      <div className="App">
        <Outlet />
      </div>
    </>
  )
};

export default Layout;
