import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Navigation/Layout";

import Search from "./pages/Search/Search";
import Results from "./pages/Search/Results";

import Signup from "./pages/Auth/Signup/Signup";
import Login from "./pages/Auth/Login/Login";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import NoPage from "./pages/ErrorResponse/NoPage";
import ProtectedRoute from "components/ProtectedRoute";
import InitialisingUserPage from "./pages/Onboarding/Initialising/Initialising"

import CreateProfileOnboarding from "pages/Onboarding/CreateProfile/CreateProfile";
import ConnectCompanyInformationOnboarding from "pages/Onboarding/CompanyInformation/CompanyInformation";
import ConnectLinkedInOnboarding from "pages/Onboarding/ConnectLinkedIn/ConnectLinkedIn";

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<ProtectedRoute><Layout/></ProtectedRoute>} >
					{/* Onboarding */}
					<Route path="create-profile" element={<CreateProfileOnboarding />} />
					<Route path="add-company-info" element={<ConnectCompanyInformationOnboarding />} />
					<Route path="connect-linkedin" element={<ConnectLinkedInOnboarding />} />
					<Route path="initialising" element={<InitialisingUserPage />} />

					{/* Standard pages */}
					<Route index element={<Search />} />
					<Route path="search" element={<Results />} />
				</Route>

				{/* Log-in & Sign-up */}
				<Route path="auth">
					<Route index element={<Login />} />
					<Route path="login" element={<Login />} />
					<Route path="signup" element={<Signup />} />
					<Route path="forgot" element={<ForgotPassword />} />
				</Route>

				<Route path="*" element={<NoPage />} />
			</Routes>
		</BrowserRouter>
	);
}