import React, { useState } from 'react';
import { Stack, Typography, Input, Button, Avatar, Select, Option, Snackbar } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

import { updateUserDocument } from 'remote/firestore'

const CreateProfileOnboarding = () => {
    const [displayName, setdisplayName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [photoURL, setphotoURL] = useState(null);
    const [gender, setGender] = useState('');
    const [location, setLocation] = useState('');
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const handlephotoURLChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setphotoURL(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formattedDateOfBirth = dateOfBirth ? new Date(dateOfBirth).toISOString() : null;

        const profileData = {
            displayName,
            dateOfBirth:formattedDateOfBirth,
            gender,
            location,
            photoURL,
        };

        try {
            await updateUserDocument(profileData);
            console.log("Profile updated!");
            navigate('/add-company-info');
        } catch (error) {
            console.error("Failed to update profile:", error);
            setErrorMessage(error);
            setSnackBarOpen(true);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ maxWidth: '500px', margin: '0 auto', padding: '20px' }}>
                <Typography level="h1" component="h1">
                    Create Your Profile
                </Typography>

                <Input
                    label="displayName"
                    placeholder="Enter your displayName"
                    value={displayName}
                    onChange={(e) => setdisplayName(e.target.value)}
                    required
                />

                <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={photoURL} alt="Profile Photo" sx={{ width: 80, height: 80 }} />
                    <Button variant="outlined" component="label">
                        Upload Profile Photo
                        <input type="file" accept="image/*" hidden onChange={handlephotoURLChange} />
                    </Button>
                </Stack>

                <Input
                    label="Date of Birth"
                    type="date"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    required
                    slotProps={{
                        input: {
                            shrink: true,
                        },
                    }}
                />

                <Select
                    label="Gender"
                    value={gender}
                    onChange={(e, newValue) => setGender(newValue)}
                    placeholder="Select your gender"
                    required
                >
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                    <Option value="female">Non-binary</Option>
                    <Option value="female">Gender Neutral</Option>
                    <Option value="other">Other</Option>
                    <Option value="prefer-not-to-say">Prefer not to say</Option>
                </Select>

                <Input
                    label="Location"
                    placeholder="What city do you work in?"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                />

                <Button type="submit" sx={{ marginTop: '20px' }}>
                    Save Profile
                </Button>
            </Stack>

            <Snackbar
                variant="solid"
                color="danger"
                size="md"
                open={snackBarOpen}
                onClose={() => setSnackBarOpen(false)}
            >
                {errorMessage}
            </Snackbar>
        </form>
    );
};

export default CreateProfileOnboarding;
